<template>
  <div>
    <ExamTypeFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(examTypes)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in examTypes" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description', ['Description'])">
            {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])">
            <TableAction
              :idx="index"
              :current-user="type"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="examsTypesCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(examsTypesCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="getAllExamTypes"
    />
    <!-- Add and edit exam type -->
    <ExamTypeModal
      v-if="isModalShow"
      :modal="isModalShow"
      :edit="currentExamType"
      @toggle="toggleExamTypeModal"
    />
    <UIConfirmationModal
      v-if="deleteModelShow"
      :modal="deleteModelShow"
      heading="DELETE_FEE_TYPE"
      message="Are you sure you want to delete "
      button-text="Delete"
      :name="currentExamType.title"
      remaining-message="Exam Type"
      @cancel="toogleDeleteModel"
      @confirm="deleteExamById"
    />
  </div>
</template>

<script>
import ExamTypeFilter from '@src/router/views/exam-planner/types/ExamTypeFilter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import isEmpty from 'lodash/isEmpty'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import ExamTypeModal from '@src/router/views/admin/modals/AddAndEditExamTypeModal.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import examTypeData from '@src/router/views/exam-planner/types/examTypeData.json'
import fileMixin from '@src/mixins/file-mixins'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'ExamTypes',
  components: {
    ExamTypeFilter,
    TableAction,
    Loader,
    Pagination,
    UIConfirmationModal,
    NoRecordFound,
    ExamTypeModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixin, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      filtersData: {},
      dashboard: 'dashboard',
      deleteModelShow: false,
      examTypes: [],
      modalButtonLoading: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      defaultSelectedColumns: examTypeData.defaultSelectedColumns,
      tableHeaders: examTypeData.tableHeaders,
      skipForFilterColumns: examTypeData.defaultSelectedColumns,
      examsTypesCounts: 0,
      currentExamType: null,
      isModalShow: false,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      activeRole: (state) => state.layout.activeRole,
    }),
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.getAllExamTypes()
      },
      deep: true,
    },
    currentClassScope: {
      handler(val) {
        if (val) this.getAllExamTypes()
      },
      deep: true,
    },
    activeRole: {
      handler() {
        this.getAllExamTypes()
      },
      deep: true,
    },
  },
  mounted() {
    this.setRightbarData()
    this.getAllExamTypes()
  },
  methods: {
    showColumnInDataTable,
    isEmpty,
    paginationCounts,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getAllExamTypes()
    },
    deleteExamById() {
      this.deleteType(this.currentExamType.id)
    },
    async getAllExamTypes(range) {
      if (!this.currentClassScope) return

      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      const [res, err] = await this.getExamTypes(payload)
      if (!err) {
        this.examTypes = res?.data?.records
        this.examsTypesCounts = res?.data?.meta?.total_records
        this.setRightbarData(res.data?.meta?.total_records)
      }
    },
    toggleExamTypeModal(payload) {
      this.isModalShow = !this.isModalShow
      if (!this.isModalShow) this.currentExamType = null
      if (payload) this.getAllExamTypes()
    },
    typeAction(action, currentType, idx) {
      this.currentExamType = currentType
      switch (action) {
        case 'Edit':
          this.toggleExamTypeModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
      }
    },

    async deleteType(id) {
      await this.deleteExamType(id)
      this.toogleDeleteModel()
      this.getAllExamTypes()
    },
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentExamType = null
    },

    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'EXAM_TYPE',
          buttons: [
            {
              title: 'ADD_EXAM_TYPE',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleExamTypeModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total Types', value: length }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('exams', ['getExamTypes', 'deleteExamType']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}

tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
